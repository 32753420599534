
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CustomerFilter } from '@/models/Charts/quotesAbcCustomersGenerator';
import QuotesAbcCustomersDetailedGenerator from '@/models/Charts/quotesAbcCustomersDetailedGenerator';
import { DetailedViewEnum } from '@/models/enums/WidgetEnum';
import { Tenant } from '@/models/tenant';
import { EChartsOption } from 'echarts';
import { QuotesAbcCustomersData } from '@/models/Charts/chartsData';
import { isEmpty } from '@/utils/misc';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import { tenantsService } from '@/services/tenants.service';

@Component({})
export default class QuotesCustomersExpandedView extends Vue {
  @Prop()
  private selectedTimeFilter!: [string, string];

  @Prop({ required: false })
  private onClose?: () => void;

  private modalOpen = true;
  private customerFilterOptions = [
    CustomerFilter.All,
    CustomerFilter.New,
    CustomerFilter.BestRates,
    CustomerFilter.WorstRates,
    CustomerFilter.Overdue,
  ];
  private selectedCustomerFilter = CustomerFilter.All;
  private noData = false;
  private loading = false;
  private data: QuotesAbcCustomersData[] | null = [];
  private option: EChartsOption | null = null;
  private generator: ChartGenerator<QuotesAbcCustomersData[]> =
    new QuotesAbcCustomersDetailedGenerator(
      DetailedViewEnum.QuotesCustomerExpandedView,
      this.tenantIdDh,
    );

  private async mounted() {
    await this.update();
  }

  private async update(): Promise<void> {
    this.loading = true;
    if (isEmpty(this.data)) {
      this.data = await this.generator.getData([], [], this.selectedTimeFilter);
    }

    if (isEmpty(this.data)) {
      this.noData = true;
      this.loading = false;
      return;
    }

    const params = {
      customerFilter: this.selectedCustomerFilter,
    };
    this.option = this.generator.updateOptions(this.data!, params);
    this.loading = false;
  }

  private async onCustomerFilterChanged(filter: CustomerFilter) {
    this.selectedCustomerFilter = filter;
    await this.update();
  }

  @Watch('modalOpen')
  private onModalOpenChanged() {
    if (!this.modalOpen && !!this.onClose) {
      this.onClose();
    }
  }

  private beforeDestroy() {
    this.generator.abort();
  }

  private get tenant(): Tenant {
    return tenantsService.store.current()!;
  }

  private get tenantIdDh(): number {
    return this.tenant.tenantIdDh;
  }
}
