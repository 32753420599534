// --------------------------------------------------------------------------------
// <copyright file="quotesAbcCustomersDetailedGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { abbreviateNumberWithCurrency, formatPercentage } from '@/utils/number';
import { EChartsOption } from 'echarts';
import { ProcedureName } from './abstract/chartGenerator';
import { QuotesAbcCustomersData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import QuotesAbcCustomersGenerator from './quotesAbcCustomersGenerator';

export default class QuotesAbcCustomersDetailedGenerator extends QuotesAbcCustomersGenerator {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    super(procedure, tenantIdDh);
  }

  override updateOptions(
    data: QuotesAbcCustomersData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const options = super.updateOptions(data, parameters, prevOptions);

    return {
      ...options,
      label: {
        position: 'insideTopLeft',
        overflow: 'breakAll',
        formatter: (params: any) => {
          if (!params.data.selection?.id) {
            // non-leaf nodes don't have the selection field
            return '';
          }

          const categoryName = i18n.t('widget_info_text.quotes_abc_customers_tooltip_category', {
            category: params.data.path.split('/')[0],
          });
          const customer = data.find((item) => item.no_ === params.data.selection.id)!;
          const formattedRatio = formatPercentage(
            (100 * customer.converted_amount) / customer.amount,
          );

          return [
            `{title|${params.name}} {category|${categoryName}}`,
            '{hr|}',
            `{highlight|${abbreviateNumberWithCurrency(customer.amount)}}` +
              ` {label|${i18n.t('widget_info_text.quotes_abc_customers_tooltip_amount')}}`,
            `{line|${abbreviateNumberWithCurrency(params.value)}} {label|${i18n.t(
              'widget_info_text.quotes_abc_customers_tooltip_converted_amount',
            )}}`,
            `{line|${customer.n_quotes}} {label|${i18n.t(
              'widget_info_text.quotes_abc_customers_tooltip_number_quotes',
            )}}`,
            `{line|${customer.n_accepted}} {label|${i18n.t(
              'widget_info_text.quotes_abc_customers_tooltip_number_converted_quotes',
            )}}`,
            `{line|${formattedRatio}}` +
              ` {label|${i18n.t('widget_info_text.quotes_abc_customers_tooltip_ratio')}}`,
          ].join('\n');
        },
        rich: {
          label: {
            fontSize: 9,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: 2,
            padding: [2, 4],
            align: 'right',
          },
          title: {
            fontSize: 14,
            fontWeight: 'bold',
            padding: 3,
          },
          category: {
            align: 'right',
          },
          hr: {
            width: '100%',
            borderColor: 'rgba(255, 255, 255, 0.2)',
            borderWidth: 0.5,
            height: 0,
            lineHeight: 10,
          },
          highlight: {
            padding: 4,
            fontSize: 12,
            fontWeight: 'bold',
          },
          line: {
            padding: 3,
          },
        },
      },
    };
  }
}
